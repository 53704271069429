import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// importation de l'axios
import axios from '../axios';
// déclaration des states initials
const initialState = {
	// objet Regies qui contient le status (false/true pour le chargement) et la liste des Regies
	prospects: {
		loading: false,
		status: false,
		list: [],
		pagination: {
			page: 1,
			limit: 10,
			totalDocs: 0,
			totalPages: 0,
			hasPrevPage: false,
			hasNextPage: false,
			prevPage: null,
			nextPage: null,
		},
	},
};

export const getAllProspects= createAsyncThunk('Prospects/getAllProspects', async (params) => {
	const search=params.search?JSON.stringify(params.search):'';
	const sort=params.sort?JSON.stringify(params.sort):'';
	const response = await axios.get(`/prospect?limit=${params.limit}&page=${params.page}&search=${search}&sort=${sort}`);
	return response.data.res;
});

export const ProspectSlice = createSlice({
	name: 'Prospects',
	initialState,
	reducers: {},

	extraReducers: {
		[getAllProspects.pending]: (state) => {
			state.prospects.status = false;
			state.prospects.loading = true;
		},
		[getAllProspects.rejected]: (state) => {
			state.prospects.status = false;
			state.prospects.loading = false;
		},
		[getAllProspects.fulfilled]: (state, action) => {
			state.prospects.status = true;
			state.prospects.loading = false;

			state.prospects.list = action.payload.docs;
			state.prospects.pagination = {
				page: action.payload.page,
				limit: action.payload.limit,
				totalDocs: action.payload.totalDocs,
				totalPages: action.payload.totalPages,
				hasPrevPage: action.payload.hasPrevPage,
				hasNextPage: action.payload.hasNextPage,
				prevPage: action.payload.prevPage,
				nextPage: action.payload.nextPage,
			};
		},
		
	},
});

export default ProspectSlice.reducer;
