import React from 'react';
// import PropTypes from 'prop-types';

// import logo from '../assets/img/logo.png';

const Logo = () => {
	return (
		<h1 className='text-info'>
			<strong>{process.env.REACT_APP_SITE_NAME}</strong>
		</h1>
	);
};
// Logo.propTypes = {
// 	width: PropTypes.number,
// 	height: PropTypes.number,
// };
// Logo.defaultProps = {
// 	width: 2155,
// 	height: 854,
// };
export default Logo;
