import React from 'react';
import PageWrapper from '../layout/PageWrapper/PageWrapper';
import Spinner from './bootstrap/Spinner';

const GlobalLoader = () => {
	return (
		<PageWrapper title='chargement...' className='m-0 p-0'>
			<div className='d-flex align-items-center justify-content-center h-100 bg-primary'>
				<div className='d-flex flex-column align-items-center justify-content-center text-light'>
					<Spinner size={36} />
					<h5 className='mt-3'>Chargement...</h5>
				</div>
			</div>
		</PageWrapper>
	);
};

export default GlobalLoader;
