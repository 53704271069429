import React from 'react';
import { useMeasure } from 'react-use';

const Footer = () => {
	const [ref, { height }] = useMeasure();

	const root = document.documentElement;
	root.style.setProperty('--footer-height', `${height}px`);

	return (
		<footer ref={ref} className='footer'>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col'>
						Copyright &copy; {new Date().getFullYear()}{' '}
						{process.env.REACT_APP_SITE_NAME}
					</div>
					<div className='col-auto'>{process.env.REACT_APP_SITE_NAME}</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
