import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// importation de l'axios
import axios from '../axios';
// déclaration des states initials
const initialState = {
	// objet Sous Regies qui contient le status (false/true pour le chargement) et la liste des sous Regies
	SousRegies: {
		loading: false,
		status: false,
		listeSousRegies: [],
		pagination: {
			page: 1,
			limit: 10,
			totalDocs: 0,
			totalPages: 0,
			hasPrevPage: false,
			hasNextPage: false,
			prevPage: null,
			nextPage: null,
		},
	},
	selectedSousRegie: { loading: false, status: false, souRegie: null },
};

export const getAllSousRegies = createAsyncThunk('sousRegie/getAllSousRegies', async (params) => {
	const search = params.search ? JSON.stringify(params.search) : '';
	const sort = params.sort ? JSON.stringify(params.sort) : '';
	const response = await axios.get(
		`/sousRegie?limit=${params.limit}&page=${params.page}&search=${search}&sort=${sort}`,
	);
	return response.data.res;
});
export const getSousRegieById = createAsyncThunk('sousRegie/getSousRegieById', async (id) => {
	try {
		const response = await axios.get(`/sousRegie/${id}`);
		return response.data.res;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});
export const addNewSousRegie = createAsyncThunk('sousRegie/addNewSousRegie', async (sousRegie) => {
	try {
		const response = await axios.post(`/sousRegie`, sousRegie);

		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

export const updateSousRegie = createAsyncThunk('sousRegie/updateSousRegie', async (sousRegie) => {
	try {
		const response = await axios.put(`/sousRegie/${sousRegie._id}`, sousRegie);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

export const deleteSousRegie = createAsyncThunk('sousRegie/deleteSousRegie', async (id) => {
	try {
		return await axios.delete(`/sousRegie/${id}`);
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

export const sousRegieSlice = createSlice({
	name: 'sousRegie',
	initialState,
	reducers: {},

	extraReducers: {
		[getAllSousRegies.pending]: (state) => {
			state.SousRegies.status = false;
			state.SousRegies.loading = true;
		},
		[getAllSousRegies.rejected]: (state) => {
			state.SousRegies.status = false;
			state.SousRegies.loading = false;
		},
		[getAllSousRegies.fulfilled]: (state, action) => {
			state.SousRegies.status = true;
			state.SousRegies.loading = false;

			state.SousRegies.listeSousRegies = action.payload.docs;
			state.SousRegies.pagination = {
				page: action.payload.page,
				limit: action.payload.limit,
				totalDocs: action.payload.totalDocs,
				totalPages: action.payload.totalPages,
				hasPrevPage: action.payload.hasPrevPage,
				hasNextPage: action.payload.hasNextPage,
				prevPage: action.payload.prevPage,
				nextPage: action.payload.nextPage,
			};
		},
		[addNewSousRegie.pending]: (state) => {
			state.SousRegies.status = false;
		},
		[addNewSousRegie.rejected]: (state) => {
			state.SousRegies.status = false;
		},
		[addNewSousRegie.fulfilled]: (state, action) => {
			state.SousRegies.status = true;
			state.SousRegies.listeSousRegies.push(action.payload.res);
		},
		[getSousRegieById.pending]: (state) => {
			state.selectedSousRegie.status = false;
			state.selectedSousRegie.loading = true;
		},
		[getSousRegieById.rejected]: (state) => {
			state.selectedSousRegie.status = false;
			state.selectedSousRegie.loading = false;
		},
		[getSousRegieById.fulfilled]: (state, action) => {
			state.selectedSousRegie.status = true;
			state.selectedSousRegie.loading = false;

			state.selectedSousRegie.souRegie = action.payload[0];
		},
		[updateSousRegie.pending]: (state) => {
			state.SousRegies.status = false;
		},
		[updateSousRegie.rejected]: (state) => {
			state.SousRegies.status = false;
		},
		[updateSousRegie.fulfilled]: (state) => {
			state.SousRegies.status = true;
		},
	},
});

export default sousRegieSlice.reducer;
