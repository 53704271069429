import React, { Suspense } from 'react';
import ContentRoutes from './ContentRoutes';
import Loader from '../../components/Loader';
// import GlobalLoader from '../../components/GlobalLoader';

const Content = () => {
	return (
		<main className='content'>
			<Suspense fallback={<Loader />}>
				<ContentRoutes />
			</Suspense>
		</main>
	);
};

export default Content;
