import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { demoPages } from '../../menu';
import useDarkMode from '../../hooks/useDarkMode';
import Collapse from '../../components/bootstrap/Collapse';
import { NavigationLine } from '../Navigation/Navigation';
import Icon from '../../components/icon/Icon';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import { logout } from '../../redux/authSlice';
import { ROLES } from '../../pages/common/Headers/DefaultHeader';

const User = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const handleItem = useNavigationItemHandle();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const { user } = useSelector((state) => state.auth);
	const [collapseStatus, setCollapseStatus] = useState(false);

	const { t } = useTranslation(['translation', 'menu']);
	const handleLogout = () => {
		dispatch(logout())
			.unwrap()
			.then(() => navigate(`../${demoPages.login.path}`));
	};

	return (
		user && (
			<>
				<div
					className={classNames('user', { open: collapseStatus })}
					role='presentation'
					onClick={() => setCollapseStatus(!collapseStatus)}>
					<div className='avatar-group'>
						<div className='avatar-more' style={{ width: '32px', height: '32px' }}>
							{user.nom.slice(0, 1).toUpperCase()}
							{user.prenom.slice(0, 1).toUpperCase()}
						</div>
					</div>
					<div className='user-info px-2'>
						<div className='user-name'>{`${user.nom} ${user.prenom}`}</div>
						<div className='user-sub-title'>{ROLES[user.role]}</div>
					</div>
				</div>

				<Collapse isOpen={collapseStatus} className='user-menu'>
					<nav aria-label='aside-bottom-user-menu'>
						<div className='navigation'>
							<div
								role='presentation'
								className='navigation-item cursor-pointer'
								onClick={() => {
									setDarkModeStatus(!darkModeStatus);
									handleItem();
								}}>
								<span className='navigation-link navigation-link-pill'>
									<span className='navigation-link-info'>
										<Icon
											icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
											color={darkModeStatus ? 'info' : 'warning'}
											className='navigation-icon'
										/>
										<span className='navigation-text'>
											{darkModeStatus
												? t('menu:DarkMode')
												: t('menu:LightMode')}
										</span>
									</span>
								</span>
							</div>
						</div>
					</nav>
					<NavigationLine />
					<nav aria-label='aside-bottom-user-menu-2'>
						<div className='navigation'>
							<div
								role='presentation'
								className='navigation-item cursor-pointer'
								onClick={handleLogout}>
								<span className='navigation-link navigation-link-pill'>
									<span className='navigation-link-info'>
										<Icon icon='Logout' className='navigation-icon' />
										<span className='navigation-text'>Se déconnecter</span>
									</span>
								</span>
							</div>
						</div>
					</nav>
				</Collapse>
			</>
		)
	);
};

export default User;
