import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import contents from '../../routes/contentRoutes';

const ContentRoutes = () => {
	const { user } = useSelector((state) => state.auth);

	const location = useLocation();
	const navigate = useNavigate();
	useEffect(() => {
		if (user) {
			if (!checkAuth(location.pathname.slice(1), user?.role))
				navigate('/404', { replace: true });
		}
	}, [location.pathname, navigate, user]);
	return (
		<Routes>
			{contents.map((page) => (
				<Route key={page.path} path={page.path} exact={page.exact} element={page.element} />
			))}
			<Route path='*' element={<Navigate to='/404' />} />
		</Routes>
	);
};

export default ContentRoutes;

const checkAuth = (path, role) => {
	const currentRoute = contents.find((page) => page.path === path.split('?')[0]);
	if (currentRoute && currentRoute.roles) {
		return currentRoute.roles.includes(role);
	}
	return true;
};
