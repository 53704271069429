import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// importation de l'axios
import axios from '../axios';
// déclaration des states initials
const initialState = {
	// objet Regies qui contient le status (false/true pour le chargement) et la liste des Regies
	Regies: {
		loading: false,
		status: false,
		listeRegies: [],
		listeRegieNoPagination: [],
		pagination: {
			page: 1,
			limit: 10,
			totalDocs: 0,
			totalPages: 0,
			hasPrevPage: false,
			hasNextPage: false,
			prevPage: null,
			nextPage: null,
		},
	},
	selectedRegie: { loading: false, status: false, Regie: null },
};

export const getAllRegies = createAsyncThunk('Regie/getAllRegies', async (params) => {
	const search = params.search ? JSON.stringify(params.search) : '';
	const sort = params.sort ? JSON.stringify(params.sort) : '';
	const response = await axios.get(
		`/regie?limit=${params.limit}&page=${params.page}&search=${search}&sort=${sort}`,
	);
	return response.data.res;
});

export const getAllRegiesWithoutPagination = createAsyncThunk(
	'Regie/getAllRegiesWithoutPagination',
	async () => {
		const response = await axios.get(`/regie_noPagination`);
		return response.data.res;
	},
);
export const getRegieById = createAsyncThunk('Regie/getRegieById', async (id) => {
	try {
		const response = await axios.get(`/regie/${id}`);
		return response.data.res;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});
export const addNewRegie = createAsyncThunk('Regie/addNewRegie', async (Regie) => {
	try {
		const response = await axios.post(`/regie`, Regie);

		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

export const updateRegie = createAsyncThunk('Regie/updateRegie', async (Regie) => {
	try {
		const response = await axios.put(`/regie/${Regie._id}`, Regie);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

export const deleteRegie = createAsyncThunk('Regie/deleteRegie', async (id) => {
	try {
		return await axios.delete(`/regie/${id}`);
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

export const regieSlice = createSlice({
	name: 'Regie',
	initialState,
	reducers: {},

	extraReducers: {
		[getAllRegies.pending]: (state) => {
			state.Regies.status = false;
			state.Regies.loading = true;
		},
		[getAllRegies.rejected]: (state) => {
			state.Regies.status = false;
			state.Regies.loading = false;
		},
		[getAllRegies.fulfilled]: (state, action) => {
			state.Regies.status = true;
			state.Regies.loading = false;

			state.Regies.listeRegies = action.payload.docs;
			state.Regies.pagination = {
				page: action.payload.page,
				limit: action.payload.limit,
				totalDocs: action.payload.totalDocs,
				totalPages: action.payload.totalPages,
				hasPrevPage: action.payload.hasPrevPage,
				hasNextPage: action.payload.hasNextPage,
				prevPage: action.payload.prevPage,
				nextPage: action.payload.nextPage,
			};
		},
		[getAllRegiesWithoutPagination.pending]: (state) => {
			state.Regies.status = false;
			state.Regies.loading = true;
		},
		[getAllRegiesWithoutPagination.rejected]: (state) => {
			state.Regies.status = false;
			state.Regies.loading = false;
		},
		[getAllRegiesWithoutPagination.fulfilled]: (state, action) => {
			state.Regies.status = true;
			state.Regies.loading = false;
			state.Regies.listeRegieNoPagination = action.payload;
		},
		[addNewRegie.pending]: (state) => {
			state.Regies.status = false;
		},
		[addNewRegie.rejected]: (state) => {
			state.Regies.status = false;
		},
		[addNewRegie.fulfilled]: (state, action) => {
			state.Regies.status = true;
			state.Regies.listeRegies.push(action.payload.res);
		},
		[getRegieById.pending]: (state) => {
			state.selectedRegie.status = false;
			state.selectedRegie.loading = true;
		},
		[getRegieById.rejected]: (state) => {
			state.selectedRegie.status = false;
			state.selectedRegie.loading = false;
		},
		[getRegieById.fulfilled]: (state, action) => {
			state.selectedRegie.status = true;
			state.selectedRegie.loading = false;

			state.selectedRegie.Regie = action.payload[0];
		},
		[updateRegie.pending]: (state) => {
			state.Regies.status = false;
		},
		[updateRegie.rejected]: (state) => {
			state.Regies.status = false;
		},
		[updateRegie.fulfilled]: (state) => {
			state.Regies.status = true;
		},
	},
});

export default regieSlice.reducer;
