import  { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setToken } from '../redux/authSlice';

import axiosInstance from './index';

const AxiosInterceptor = () => {
	const { token } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	useEffect(() => {
		const reqInterceptor = (config) => {
			if (token) {
				config.headers.authorization = token;
			}
			return config;
		};

		const reqErrInterceptor = (error) => {
			return Promise.reject(error);
		};

		const resInterceptor = (res) => {
			return res;
		};
		const resErrInterceptor = async (err) => {
			const originalConfig = err.config;
			if (
				originalConfig.url !== '/auth/login' &&
				originalConfig.url !== '/auth/refreshToken' &&
				err.response
			) {
				// Access Token was expired
				if (err.response.status === 401 && !originalConfig._retry) {
					originalConfig._retry = true;
					try {
						const response = await axiosInstance.get('/auth/refreshToken', {
							withCredentials: true,
						});
						dispatch(setToken(response.data.token));
						return axiosInstance(originalConfig);
					} catch (_error) {
						navigate('/login', { replace: true });
						// window.location = '/login';
						return Promise.reject(_error);
					}
				}
			}
			return Promise.reject(err);
		};
		const reqUseInterceptor = axiosInstance.interceptors.request.use(
			reqInterceptor,
			reqErrInterceptor,
		);
		const resUseInterceptor = axiosInstance.interceptors.response.use(
			resInterceptor,
			resErrInterceptor,
		);

		return () => {
			axiosInstance.interceptors.request.eject(reqUseInterceptor);
			axiosInstance.interceptors.response.eject(resUseInterceptor);
		};
	}, [dispatch, navigate, token]);
	return '';
};

export default AxiosInterceptor;
