import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { TourProvider } from '@reactour/tour';
import { useDispatch, useSelector } from 'react-redux';

import ThemeContext from '../contexts/themeContext';
import Aside from '../layout/Aside/Aside';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import { demoPages, layoutMenu } from '../menu';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';
import steps, { styles } from '../steps';
import { refreshToken } from '../redux/authSlice';
// import Loader from '../layout/Loader';
import GlobalLoader from '../components/GlobalLoader';

const App = () => {
	const { loading, user, init } = useSelector((state) => state.auth);
	const location = useLocation();
	getOS();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};
	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	//	Add paths to the array that you don't want to be "Aside".
	const withOutAsidePages = useMemo(
		() => [
			demoPages.login.path,
			demoPages.resetPasswordForm.path,
			layoutMenu.blank.path,
			demoPages.page404.path,
		],
		[],
	);

	const PublicRoutes = useMemo(
		() => [
			demoPages.resetPasswordForm.path,
			demoPages.resetPassword.path,
			demoPages.page404.path,
		],
		[],
	);

	useEffect(() => {
		if (!PublicRoutes.includes(location.pathname.slice(1))) {
			dispatch(refreshToken())
				.unwrap()
				.catch(() => {
					navigate('/login');
				});
		}
	}, [dispatch, withOutAsidePages, PublicRoutes]);
	useEffect(() => {
		if (init && !user) {
			navigate('/login');
		}
	}, [init, navigate, user]);
	return (
		<ThemeProvider theme={theme}>
			<ToastProvider components={{ ToastContainer, Toast }}>
				{loading ? (
					<GlobalLoader />
				) : (
					<TourProvider
						steps={steps}
						styles={styles}
						showNavigation={false}
						showBadge={false}>
						<div
							ref={ref}
							className='app'
							style={{
								backgroundColor: fullScreenStatus && 'var(--bs-body-bg)',
								zIndex: fullScreenStatus && 1,
								overflow: fullScreenStatus && 'scroll',
							}}>
							<Routes>
								{withOutAsidePages.map((path) => (
									<Route key={path} path={path} />
								))}
								<Route path='*' element={<Aside />} />
							</Routes>
							<Wrapper />
						</div>
						<Portal id='portal-notification'>
							<ReactNotifications />
						</Portal>
					</TourProvider>
				)}
			</ToastProvider>
		</ThemeProvider>
	);
};

export default App;
