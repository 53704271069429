import React, { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import Tooltips from '../../../components/bootstrap/Tooltips';
import InputGroup from '../../../components/bootstrap/forms/InputGroup';
import { resetPassword } from '../../../redux/authSlice';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';

const validationSchema = yup.object({
	password: yup
		.string('Enter your password')
		.min(8, 'Le mot de passe doit être au minimum 8 characters')
		.required('Mot de passe obligatoire'),
	ConfirmPassword: yup
		.string('Enter your password')
		.min(8, 'Le mot de passe doit être au minimum 8 characters')
		.required('Mot de passe obligatoire')
		.oneOf([yup.ref('password'), null], 'mot de passe ne sont pas identiques'),
});

const LoginHeader = () => {
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Bienvenue,</div>
			<div className='text-center h4 text-muted mb-5'>
				Merci de saisir votre nouveau mot de passe !
			</div>
		</>
	);
};

const ForgetPassword = () => {
	const { darkModeStatus } = useDarkMode();

	const [viewPassword, setViewPassword] = useState(false);
	const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
	const [searchParams] = useSearchParams();
	const tokenResetPassword = searchParams.get('token');
	const navigate = useNavigate();

	const dispatch = useDispatch();
	const formik = useFormik({
		initialValues: {
			password: '',
			ConfirmPassword: '',
		},
		validationSchema,
		onSubmit: async (values) => {
			dispatch(resetPassword({ token: tokenResetPassword, newPassword: values.password }))
				.unwrap()
				.then((res) => {
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>Opération terminé avec succées</span>
						</span>,
						res.message,
						'success',
					);
					navigate('/login');
				})
				.catch((error) => {
					if (error.message === 'invalid Token') {
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Error' size='lg' className='me-1' />
								<span>Merci de réessayer de nouveau</span>
							</span>,
							'Le lien de réinitialisation de mot de passe est expiré',
							'danger',
						);
					} else
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Error' size='lg' className='me-1' />
								<span>Erreur</span>
							</span>,
							'On s excuse un erreur c est produit, veuillez réessayer plus tard',
							'danger',
						);
				});
		},
	});

	return (
		<PageWrapper title='Changer votre mot de passe' className={classNames('bg-light')}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<Logo width={200} />
									</Link>
								</div>

								<LoginHeader />

								<form className='row g-4'>
									<div className='col-12'>
										<FormGroup
											label='Nouveau mot de passe'
											className='col-md-12'>
											<InputGroup>
												<Input
													id='password'
													name='password'
													autoComplete='new-password'
													type={viewPassword ? 'text' : 'password'}
													placeholder='Nouveau mot de passe'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.password}
													isValid={formik.isValid}
													isTouched={formik.touched.password}
													invalidFeedback={formik.errors.password}
												/>
												<Tooltips
													flip={['top', 'bottom']}
													placement='bottom'
													title={
														viewPassword
															? 'Cacher le mot de passe'
															: 'Voir le mot de passe'
													}>
													<Button
														icon={viewPassword ? 'Eye' : 'EyeSlash'}
														color='light'
														onClick={() => setViewPassword((v) => !v)}
													/>
												</Tooltips>
											</InputGroup>
										</FormGroup>
									</div>
									<div className='col-12'>
										<FormGroup
											label='Confirme Mot de passe'
											className='col-md-12'>
											<InputGroup>
												<Input
													id='ConfirmPassword'
													name='ConfirmPassword'
													autoComplete='new-password'
													type={viewConfirmPassword ? 'text' : 'password'}
													placeholder='Confirmer votre Mot de passe'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.ConfirmPassword}
													isValid={formik.isValid}
													isTouched={formik.touched.ConfirmPassword}
													invalidFeedback={formik.errors.ConfirmPassword}
												/>
												<Tooltips
													flip={['top', 'bottom']}
													placement='bottom'
													title={
														viewConfirmPassword
															? 'Cacher le mot de passe'
															: 'Voir le mot de passe'
													}>
													<Button
														icon={
															viewConfirmPassword ? 'Eye' : 'EyeSlash'
														}
														color='light'
														onClick={() =>
															setViewConfirmPassword((v) => !v)
														}
													/>
												</Tooltips>
											</InputGroup>
										</FormGroup>
									</div>

									<div className='col-12'>
										<Button
											color='info'
											icon='lockFill'
											className='w-100 py-3'
											disable={!formik.isValid}
											onClick={formik.handleSubmit}>
											Modifier
										</Button>
									</div>
								</form>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default ForgetPassword;
