import { configureStore } from '@reduxjs/toolkit';

import auth from './authSlice';
import ProspectSlice from './ProspectSlice';
import OffreSlice from './OffreSlice';
import RegieSlice from './RegieSlice';
import SousRegieSlice from './SousRegieSlice';

const store = configureStore({
	reducer: {
		auth,
		prospects: ProspectSlice,
		regie: RegieSlice,
		offre: OffreSlice,
		sousRegie: SousRegieSlice,
	},
});
export default store;
