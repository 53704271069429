import React, { lazy } from 'react';
import { demoPages, layoutMenu, RegieEtTelepros, regie, sousRegies, offre } from '../menu';
import Login from '../pages/presentation/auth/Login';
import ResetPassword from '../pages/presentation/auth/ForgetPassword';
import ResetPasswordForm from '../pages/presentation/auth/ResetPasswordForm';
import { Navigate } from 'react-router-dom';

const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};

const REGIES = {
	REGIES: lazy(() => import('../pages/Regie/ListRegie')),
	PROSPECTS: lazy(() => import('../pages/Prospect/ListProspects')),
	DETAIL_PROSPECT: lazy(() => import('../pages/Prospect/DetailsProspect/DetailProspect')),
	ADD_REGIES: lazy(() => import('../pages/Regie/ManageRegie')),
	UPDATE_REGIES: lazy(() => import('../pages/Regie/ManageRegie')),
};

const SOUS_REGIES = {
	SOUS_REGIES: lazy(() => import('../pages/SousRegie/ListSousRegie')),
	ADD_SOUS_REGIES: lazy(() => import('../pages/SousRegie/ManageSousRegie')),
	UPDATE_SOUS_REGIES: lazy(() => import('../pages/SousRegie/ManageSousRegie')),
};

const OFFRES = {
	LIST: lazy(() => import('../pages/OffreCommercial/ListOffres')),
	MANAGE: lazy(() => import('../pages/OffreCommercial/ManageOffre')),
};

const PAGE_LAYOUTS = {
	HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),

	HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
	SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
	CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
	BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
};

const REGLAGE = {
	MAILOWNER: lazy(() => import('../pages/reglage/Mailowner')),
};
const presentation = [
	/** Etudiant */

	/**
	 * Landing
	 */

	{
		path: '/',
		element: <Navigate replace to='/login' />,
		exact: true,
	},
	{
		path: '/reglage',
		element: <REGLAGE.MAILOWNER />,
		exact: true,
		roles: ['admin'],
	},

	{
		path: demoPages.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: demoPages.resetPassword.path,
		element: <ResetPassword />,
		exact: true,
	},
	{
		path: demoPages.resetPasswordForm.path,
		element: <ResetPasswordForm />,
		exact: true,
	},
	{
		path: demoPages.login.path,
		element: <Login />,
		exact: true,
		isLogin: true,
	},

	/** ************************************************** */
	/**
	 * Page Régies et Télépros
	 */
	{
		path: regie.regiesManager.path,
		element: <REGIES.REGIES />,
		exact: true,
		roles: ['admin'],
	},
	{
		path: RegieEtTelepros.prospect.path,
		element: <REGIES.PROSPECTS />,
		exact: true,
	},
	{
		path: `${RegieEtTelepros.prospect.path}/detail/:id`,
		element: <REGIES.DETAIL_PROSPECT />,
		exact: true,
	},
	{
		path: `${regie.regiesManager.path}/ajouter`,
		element: <REGIES.ADD_REGIES />,
		exact: true,
		roles: ['admin'],
	},
	{
		path: `${regie.regiesManager.path}/modifier/:id`,
		element: <REGIES.UPDATE_REGIES />,
		exact: true,
		roles: ['admin'],
	},

	{
		path: sousRegies.sousRegies.path,
		element: <SOUS_REGIES.SOUS_REGIES />,
		exact: true,
		roles: ['admin', 'regie'],
	},
	{
		path: `${sousRegies.sousRegies.path}/ajouter`,
		element: <SOUS_REGIES.ADD_SOUS_REGIES />,
		exact: true,
		roles: ['admin', 'regie'],
	},
	{
		path: `${sousRegies.sousRegies.path}/modifier/:id`,
		element: <SOUS_REGIES.UPDATE_SOUS_REGIES />,
		exact: true,
		roles: ['admin', 'regie'],
	},

	/** ************************************************** */
	{
		path: `${offre.offres.path}/modifier/:id`,
		element: <OFFRES.MANAGE />,
		exact: true,
		roles: ['admin'],
	},
	{
		path: `${offre.offres.path}/ajouter/`,
		element: <OFFRES.MANAGE />,
		exact: true,
		roles: ['admin'],
	},
	{
		path: `${offre.offres.path}`,
		element: <OFFRES.LIST />,
		exact: true,
		roles: ['admin'],
	},
	// {
	// 	path: RegieEtTelepros.telepros.path,
	// 	element: <REGIES.TELEPROS />,
	// 	exact: true,
	// 	roles: ['super administrateur', 'administrateur'],
	// },

	/** ************************************************** */
	/**
	 * Page Layout Types
	 */
	// {
	// 	path: layoutMenu.blank.path,
	// 	element: <PAGE_LAYOUTS.BLANK />,
	// 	exact: true,
	// },
	// {
	// 	path: layoutMenu.pageLayout.subMenu.headerAndSubheader.path,
	// 	element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
	// 	exact: true,
	// },

	// {
	// 	path: layoutMenu.pageLayout.subMenu.onlyHeader.path,
	// 	element: <PAGE_LAYOUTS.HEADER />,
	// 	exact: true,
	// },
	// {
	// 	path: layoutMenu.pageLayout.subMenu.onlySubheader.path,
	// 	element: <PAGE_LAYOUTS.SUBHEADER />,
	// 	exact: true,
	// },
	// {
	// 	path: layoutMenu.pageLayout.subMenu.onlyContent.path,
	// 	element: <PAGE_LAYOUTS.CONTENT />,
	// 	exact: true,
	// },
	// {
	// 	path: layoutMenu.asideTypes.subMenu.defaultAside.path,
	// 	element: <PAGE_LAYOUTS.ASIDE />,
	// 	exact: true,
	// },
	// {
	// 	path: layoutMenu.asideTypes.subMenu.minimizeAside.path,
	// 	element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
	// 	exact: true,
	// },
];
const contents = [...presentation];

export default contents;
