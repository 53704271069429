import React from 'react';
import Page from '../layout/Page/Page';
import PageWrapper from '../layout/PageWrapper/PageWrapper';
import Card from './bootstrap/Card';
import Spinner from './bootstrap/Spinner';

const Loader = () => {
	return (
		<PageWrapper title='Chargement...'>
			<Page>
				<Card stretch>
					<div className='d-flex align-items-center justify-content-center h-100'>
						<div className='d-flex flex-column align-items-center justify-content-center'>
							<Spinner size={36} />
							<h5 className='mt-3'>Chargement...</h5>
						</div>
					</div>
				</Card>
			</Page>
		</PageWrapper>
	);
};

export default Loader;
