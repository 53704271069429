import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import showNotification from '../../../components/extras/showNotification';
import { login } from '../../../redux/authSlice';
import InputGroup from '../../../components/bootstrap/forms/InputGroup';

const validationSchema = yup.object({
	email: yup.string().email('email invalide').required('email obligatoir'),
	password: yup.string().required('Mot de passe obligatoir'),
});

const Login = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);

	const { darkModeStatus } = useDarkMode();

	const { error, user } = useSelector((state) => state.auth);
	const loginForm = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema,
		onSubmit: async (values) => {
			dispatch(login(values))
				.unwrap()
				.then(() => navigate('/prospects'));
		},
	});
	useEffect(() => {
		if (error) showNotification(error, '', 'danger');
	}, [error]);
	useEffect(() => {
		if (user) navigate('/prospects');
	}, [navigate, user]);

	const handleOnClickResetPassword = useCallback(
		() => navigate('/mot-de-passe-oublie'),
		[navigate],
	);

	const togglePassword = () => {
		setShowPassword(!showPassword);
	};

	return (
		<PageWrapper title='Login' className='bg-primary'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<Logo width={200} />
									</Link>
								</div>

								<div className='text-center h1 fw-bold mt-5'>Bienvenue,</div>
								<div className='text-center h4 text-muted mb-5'>
									Connectez-vous pour continuer !
								</div>

								<form className='row g-4'>
									<div className='col-12'>
										<FormGroup
											id='login-email'
											//isFloating
											label='Votre email ou identifiant'>
											<Input
												autoComplete='email'
												// eslint-disable-next-line react/jsx-props-no-spreading
												{...loginForm.getFieldProps('email')}
											/>
										</FormGroup>

										<FormGroup
											id='login-password'
											//isFloating
											className='mt-3'
											label='Mot de passe'>
											<InputGroup>
												<Input
													type={showPassword ? 'text' : 'password'}
													autoComplete='password'
													// eslint-disable-next-line react/jsx-props-no-spreading
													{...loginForm.getFieldProps('password')}
												/>
												<Button
													icon={showPassword ? 'eye-slash' : 'eye'}
													color='light'
													onClick={togglePassword}></Button>
											</InputGroup>
										</FormGroup>
									</div>
									<div className='col-12 mt-3'>
										<Button
											isOutline
											color={darkModeStatus ? 'light' : 'dark'}
											className={classNames('w-100 py-3', {
												'border-light': !darkModeStatus,
												'border-dark': darkModeStatus,
											})}
											icon='LockFill'
											onClick={handleOnClickResetPassword}>
											Mot de passe oublié ?
										</Button>
									</div>
									<div className='col-12'>
										<Button
											color='primary'
											className='w-100 py-3'
											type='submit'
											onClick={loginForm.handleSubmit}>
											Se connecter
										</Button>
									</div>
								</form>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Login;
