import React, { useContext, useRef } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';

import Brand from '../Brand/Brand';
import Navigation, { NavigationLine } from '../Navigation/Navigation';
import User from '../User/User';
import { menu,regieMenu,sregieMenu } from '../../menu';
import ThemeContext from '../../contexts/themeContext';
import useAsideTouch from '../../hooks/useAsideTouch';
import Tooltips from '../../components/bootstrap/Tooltips';
import ChangePasswordModal from '../../components/ChangePasswordModal';

const Aside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const { user } = useSelector((state) => state.auth);
	// const { asideStyle } = useAsideTouch();
	const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } = useAsideTouch();
	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';
	const constraintsRef = useRef(null);
	const currentMenu = user?.role==="admin"?menu:user?.role==="regie"?regieMenu:sregieMenu;
	return (
		user && (
			<>
				<motion.aside
					style={asideStyle}
					className={classNames(
						'aside',
						{ open: asideStatus },
						{
							'aside-touch-bar': hasTouchButton && isModernDesign,
							'aside-touch-bar-close':
								!touchStatus && hasTouchButton && isModernDesign,
							'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
						},
					)}>
					<div className='aside-head'>
						<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
					</div>
					<div className='aside-body'>
						{currentMenu.map((item, index) => {
							/* const itemCopy = { ...item };
							const roles = itemCopy.roles ? [...itemCopy.roles] : [];
							delete itemCopy.roles; */
							return item ? (
								// eslint-disable-next-line react/no-array-index-key
								<React.Fragment key={index}>
									{item.textHeader && <NavigationLine />}
									<Navigation menu={item} id={index.toString()} />
								</React.Fragment>
							) : null;
						})}
					</div>
					<div className='aside-foot'>
						<User />
					</div>
				</motion.aside>
				{asideStatus && hasTouchButton && isModernDesign && (
					<>
						<motion.div className='aside-drag-area' ref={constraintsRef} />
						<Tooltips title='Toggle Aside' flip={['top', 'right']}>
							<motion.div
								className='aside-touch'
								drag='x'
								whileDrag={{ scale: 1.2 }}
								whileHover={{ scale: 1.1 }}
								dragConstraints={constraintsRef}
								// onDrag={(event, info) => console.log(info.point.x, info.point.y)}
								dragElastic={0.1}
								style={{ x, zIndex: 1039 }}
								onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}
							/>
						</Tooltips>
					</>
				)}
				<ChangePasswordModal />
			</>
		)
	);
};

export default Aside;
