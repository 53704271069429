import React from 'react';
import { useSelector } from 'react-redux';

import Button from '../../../components/bootstrap/Button';
import Popovers from '../../../components/bootstrap/Popovers';
import useDarkMode from '../../../hooks/useDarkMode';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';

const DefaultHeader = () => {
	const { user } = useSelector((state) => state.auth);
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	return (
		<Header hasLeftMobileMenu={false}>
			<HeaderLeft>{process.env.REACT_APP_SITE_NAME}</HeaderLeft>
			<HeaderRight>
				{' '}
				<div className='d-flex align-items-center'>
					<Popovers trigger='hover' desc='Mode Jour / nuit'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
							onClick={() => setDarkModeStatus(!darkModeStatus)}
							aria-label='Toggle dark mode'
							data-tour='dark-mode'
							className='me-3'
						/>
					</Popovers>

					{user && (
						<>
							<div className='avatar-group '>
								<div
									className='avatar-more'
									style={{ width: '32px', height: '32px' }}>
									{user.nom.slice(0, 1).toUpperCase()}
									{user.prenom.slice(0, 1).toUpperCase()}
								</div>
							</div>
							<div className='user-info px-2'>
								<div className='user-name'>{`${user.nom} ${user.prenom}`}</div>
								<div className='user-sub-title'>{ROLES[user.role]}</div>
							</div>
						</>
					)}
				</div>
			</HeaderRight>
		</Header>
	);
};

export default DefaultHeader;


export const ROLES={
	"admin":"Administrateur",
	"regie":"Régie",
	"sous-regie":"Sous Régie",
}