import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { changePassword } from '../../redux/authSlice';

import Button from '../bootstrap/Button';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import InputGroup from '../bootstrap/forms/InputGroup';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal';
import Tooltips from '../bootstrap/Tooltips';
import showNotification from '../extras/showNotification';
import Icon from '../icon/Icon';

const validationSchema = yup.object({
	password: yup
		.string('Enter your password')
		.min(8, 'Le mot de passe doit être au minimum 8 characters')
		.required('Mot de passe obligatoire'),
	ConfirmPassword: yup
		.string('Enter your password')
		.min(8, 'Le mot de passe doit être au minimum 8 characters')
		.required('Mot de passe obligatoire')
		.oneOf([yup.ref('password'), null], 'mot de passe ne sont pas identiques'),
});
const ChangePasswordModal = () => {
	const { user } = useSelector((state) => state.auth);
	const [modalOpen, setModalOpen] = useState(false);
	const [viewPassword, setViewPassword] = useState(false);
	const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
	const dispatch = useDispatch();
	useEffect(() => {
		if (user) {
			setModalOpen(false);
		}
	}, [user]);
	const formik = useFormik({
		initialValues: {
			password: '',
			ConfirmPassword: '',
		},
		validationSchema,
		onSubmit: async (values) => {
			dispatch(
				changePassword({
					password: values.password,
					isFirstLogin: false,
				}),
			)
				.unwrap()
				.then(() => {
					setModalOpen(false);
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>Succès</span>
						</span>,
						'Votre mot de passe a été mise à jour avec succès',
						'success',
					);
				})
				.catch(() => {
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>Erreur</span>
						</span>,
						'Une erreur est survenue lors de la mise à jour de votre mot de passe',
						'danger',
					);
				});
		},
	});

	return (
		<Modal
			id='Modal'
			titleId='Confirmer choix'
			isOpen={modalOpen}
			setIsOpen={setModalOpen}
			isCentered>
			<ModalHeader setIsOpen={setModalOpen}>
				<ModalTitle id='titleModal'>
					<Icon icon='Key' size='lg' className='me-1' />
					Changer votre mot de passe
				</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<h6 className='mt-3'>
					Votre mot de passe actuelle est générer par l'un de nos employés.
					<br /> Pour votre sécurité merci de le changer.
				</h6>
				<form className='row g-4 mt-4'>
					<div className='col-12'>
						<FormGroup label='Nouveau mot de passe' className='col-md-12'>
							<InputGroup>
								<Input
									id='password'
									name='password'
									autoComplete='new-password'
									type={viewPassword ? 'text' : 'password'}
									placeholder='Nouveau mot de passe'
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.password}
									isValid={formik.isValid}
									isTouched={formik.touched.password}
									invalidFeedback={formik.errors.password}
								/>
								<Tooltips
									flip={['top', 'bottom']}
									placement='bottom'
									title={
										viewPassword
											? 'Cacher le mot de passe'
											: 'Voir le mot de passe'
									}>
									<Button
										icon={viewPassword ? 'Eye' : 'EyeSlash'}
										color='light'
										onClick={() => setViewPassword((v) => !v)}
									/>
								</Tooltips>
							</InputGroup>
						</FormGroup>
					</div>
					<div className='col-12'>
						<FormGroup label='Confirme Mot de passe' className='col-md-12'>
							<InputGroup>
								<Input
									id='ConfirmPassword'
									name='ConfirmPassword'
									autoComplete='new-password'
									type={viewConfirmPassword ? 'text' : 'password'}
									placeholder='Confirmer votre Mot de passe'
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.ConfirmPassword}
									isValid={formik.isValid}
									isTouched={formik.touched.ConfirmPassword}
									invalidFeedback={formik.errors.ConfirmPassword}
								/>
								<Tooltips
									flip={['top', 'bottom']}
									placement='bottom'
									title={
										viewConfirmPassword
											? 'Cacher le mot de passe'
											: 'Voir le mot de passe'
									}>
									<Button
										icon={viewConfirmPassword ? 'Eye' : 'EyeSlash'}
										color='light'
										onClick={() => setViewConfirmPassword((v) => !v)}
									/>
								</Tooltips>
							</InputGroup>
						</FormGroup>
					</div>
				</form>
			</ModalBody>
			<ModalFooter>
				<Button color='light' onClick={() => setModalOpen(false)}>
					Annuler
				</Button>
				<Button color='primary' disable={!formik.isValid} onClick={formik.handleSubmit}>
					Confirmer
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default ChangePasswordModal;
