export function test() {
	return null;
}

export function getOS() {
	const { userAgent } = window.navigator;
	const { platform } = window.navigator;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
	let os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'MacOS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	document.documentElement.setAttribute('os', os);
	return os;
}

export const hasNotch = () => {
	/**
	 * For storybook test
	 */
	const storybook = window.location !== window.parent.location;
	const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
	const aspect = window.screen.width / window.screen.height;
	const aspectFrame = window.innerWidth / window.innerHeight;
	return (
		(iPhone && aspect.toFixed(3) === '0.462') ||
		(storybook && aspectFrame.toFixed(3) === '0.462')
	);
};

export const mergeRefs = (refs) => {
	return (value) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				ref(value);
			} else if (ref != null) {
				ref.current = value;
			}
		});
	};
};

export const randomColor = () => {
	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];

	const color = Math.floor(Math.random() * colors.length);

	return colors[color];
};

export const priceFormat = (price) => {
	return price.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	});
};

export const average = (array) => array.reduce((a, b) => a + b) / array.length;

export const percent = (value1, value2) => ((value1 / value2 - 1) * 100).toFixed(2);

export const getFirstLetter = (text, letterCount = 2) =>
	text
		.toUpperCase()
		.match(/\b(\w)/g)
		.join('')
		.substring(0, letterCount);

export const debounce = (func, wait = 1000) => {
	let timeout;

	return function executedFunction(...args) {
		const later = () => {
			clearTimeout(timeout);
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};
export const randomColorByID = (id) => {
	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];
	const v = generateNumberBetweenZeroAndOneFromString(id);

	/* const color = Math.floor(Math.random() * colors.length); */

	return colors[v];
};

const generateNumberBetweenZeroAndOneFromString = (string) => {
	let hash = 0;
	let i = 0;
	let chr;
	let len;
	let code;
	if (string.length === 0) {
		return hash;
	}
	// eslint-disable-next-line no-plusplus
	for (i = 0, len = string.length; i < len; i++) {
		chr = string.charCodeAt(i);
		code = chr;
		// eslint-disable-next-line no-bitwise
		hash = (hash << 5) - hash + code;
		// eslint-disable-next-line no-bitwise
		hash |= 0; // Convert to 32bit integer
	}
	return Math.abs(hash % 6);
};

export const listeDureeDuSeance = [
	{ label: '1h', value: '1:00' },
	{ label: '1h15', value: '1:15' },
	{ label: '1h30', value: '1:30' },
	{ label: '1h45', value: '1:45' },
	{ label: '2h', value: '2:00' },
	{ label: '2h15', value: '2:15' },
	{ label: '2h30', value: '2:30' },
	{ label: '2h45', value: '2:45' },
	{ label: '3h', value: '3:00' },
	{ label: '3h15', value: '3:15' },
	{ label: '3h30', value: '3:30' },
	{ label: '3h45', value: '3:45' },
	{ label: '4h', value: '4:00' },
	{ label: '4h15', value: '4:15' },
	{ label: '4h30', value: '4:30' },
	{ label: '4h45', value: '4:45' },
	{ label: '5h', value: '5:00' },
	{ label: '5h15', value: '5:15' },
	{ label: '5h30', value: '5:30' },
	{ label: '5h45', value: '5:45' },
	{ label: '6h', value: '6:00' },
	{ label: '6h15', value: '6:15' },
	{ label: '6h30', value: '6:30' },
	{ label: '6h45', value: '6:45' },
	{ label: '7h', value: '7:00' },
];
