import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosOriginal from 'axios';

import axios, { baseURL } from '../axios';

export const login = createAsyncThunk('auth/login', async (values) => {
	try {
		const response = await axios.post('/auth/login', values, {
			withCredentials: true,
		});
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.error,
			data: error.response.data,
		};
		throw customError;
	}
});
export const refreshToken = createAsyncThunk('auth/refreshToken', async () => {
	try {
		const response = await axiosOriginal.get(`${baseURL}/auth/refreshToken`, {
			withCredentials: true,
		});
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.error,
			data: error.response.data,
		};
		throw customError;
	}
});

export const logout = createAsyncThunk('auth/logout', async () => {
	const response = await axios.get('/auth/logout', {
		withCredentials: true,
	});
	return response.data;
});
export const sendMailResetPassword = createAsyncThunk(
	'auth/sendMailResetPassword',
	async (values) => {
		try {
			const response = await axios.post('/auth/forgetPassword', values, {
				withCredentials: true,
			});
			return response.data;
		} catch (error) {
			const customError = {
				name: 'Custom axios error',
				message: error.response.data.message,
				data: error.response.data,
			};
			throw customError;
		}
	},
);

export const resetPassword = createAsyncThunk('auth/resetPassword', async (values) => {
	try {
		const response = await axios.post('/auth/verifResetPasswordToken', values, {
			withCredentials: true,
		});
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			type: error.response.data.type,
			message: error.response.data.type,
			data: error.response.data,
		};
		throw customError;
	}
});

export const changePassword = createAsyncThunk('auth/changePassword', async (values) => {
	try {
		const response = await axios.put('/user', values);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			data: error.response.data,
		};
		throw customError;
	}
});

export const updateUser = createAsyncThunk('auth/updateUser', async (values) => {

	try {
		const response = await axios.put('/user', values);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			data: error.response.data,
		};
		throw customError;
	}
});


export const authSlice = createSlice({
	name: 'auth',
	initialState: {
		user: null,
		token: null,
		loading: false,
		error: null,
		init: false,
	},
	reducers: {
		setToken: (state, action) => {
			state.token = action.payload;
			state.loading = false;
		},
	},
	extraReducers: {
		[updateUser.fulfilled]: (state, action) => {
			state.user = {
				...state.user,
				...action.payload,
			};
		},
		[login.pending]: (state) => {
			state.error = null;
		},
		[login.fulfilled]: (state, action) => {
			state.user = {
				firstName: action.payload.user.etudiantData?.nom || '',
				lastName: '',
				...action.payload.user.userOfData,
				...action.payload.user.teleproData,
				...action.payload.user.formateurData,
				...action.payload.user.etudiantData,
				...action.payload.user,
			};
			state.token = action.payload.token;
			state.loading = false;
			state.init = true;
		},
		[refreshToken.pending]: (state) => {
			state.error = null;
			state.loading = true;
		},
		[refreshToken.fulfilled]: (state, action) => {
			state.user = {
				firstName: action.payload.user.etudiantData?.nom || '',
				lastName: '',

				...action.payload.user.userOfData,
				...action.payload.user.teleproData,
				...action.payload.user.formateurData,
				...action.payload.user.etudiantData,
				...action.payload.user,
			};
			state.init = true;

			state.token = action.payload.token;
			state.loading = false;
		},
		[refreshToken.rejected]: (state) => {
			state.user = null;
			state.token = null;
			state.loading = false;
		},

		[logout.fulfilled]: (state) => {
			state.user = null;
			state.init = false;
			state.token = null;
		},
		[login.rejected]: (state, action) => {
			state.error = action.error.message;
			state.loading = false;
		},
		[sendMailResetPassword.fulfilled]: (state) => {
			state.loading = false;
		},
		[sendMailResetPassword.rejected]: (state, action) => {
			state.error = action.error.message;
			state.loading = false;
		},
		[resetPassword.fulfilled]: (state) => {
			state.loading = false;
		},
		[resetPassword.rejected]: (state) => {
			state.loading = false;
		},
	},
});

export const { setToken } = authSlice.actions;
export default authSlice.reducer;
