import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// importation de l'axios
import axios from '../axios';
// déclaration des states initials
const initialState = {
	// objet Regies qui contient le status (false/true pour le chargement) et la liste des Regies
	Offres: {
		loading: false,
		status: false,
		listeOffres: [],
	},
	selectedOffre: { loading: false, status: false, Offre: null },
};

export const getAllOffres = createAsyncThunk('Offre/getAllOffres', async (params) => {
	const response = await axios.get(`/offre`);
	return response.data;
});
export const getOffreById = createAsyncThunk('Offre/getOffreById', async (id) => {
	try {
		const response = await axios.get(`/offre/${id}`);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});
export const addNewOffre = createAsyncThunk('Offre/addNewOffre', async (Offre) => {
	try {
		const response = await axios.post(`/offre`, Offre);

		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

export const updateOffre = createAsyncThunk('Offre/updateOffre', async (Offre) => {
	try {
		const response = await axios.put(`/offre/${Offre._id}`, Offre);
		return response.data;
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

export const deleteOffre = createAsyncThunk('Offre/deleteOffre', async (id) => {
	try {
		return await axios.delete(`/offre/${id}`);
	} catch (error) {
		const customError = {
			name: 'Custom axios error',
			message: error.response.data.message,
			/* data: error.response.data, */
		};
		throw customError;
	}
});

export const offreSlice = createSlice({
	name: 'Offre',
	initialState,
	reducers: {},

	extraReducers: {
		[getAllOffres.pending]: (state) => {
			state.Offres.status = false;
			state.Offres.loading = true;
		},
		[getAllOffres.rejected]: (state) => {
			state.Offres.status = false;
			state.Offres.loading = false;
		},
		[getAllOffres.fulfilled]: (state, action) => {
			state.Offres.status = true;
			state.Offres.loading = false;

			state.Offres.listeOffres = action.payload;
		},
		[addNewOffre.pending]: (state) => {
			state.Offres.status = false;
		},
		[addNewOffre.rejected]: (state) => {
			state.Offres.status = false;
		},
		[addNewOffre.fulfilled]: (state, action) => {
			state.Offres.status = true;
			state.Offres.listeOffres.push(action.payload);
		},
		[getOffreById.pending]: (state) => {
			state.selectedOffre.status = false;
			state.selectedOffre.loading = true;
		},
		[getOffreById.rejected]: (state) => {
			state.selectedOffre.status = false;
			state.selectedOffre.loading = false;
		},
		[getOffreById.fulfilled]: (state, action) => {
			state.selectedOffre.status = true;
			state.selectedOffre.loading = false;

			state.selectedOffre.Offre = action.payload;
		},
		[updateOffre.pending]: (state) => {
			state.Offres.status = false;
		},
		[updateOffre.rejected]: (state) => {
			state.Offres.status = false;
		},
		[updateOffre.fulfilled]: (state) => {
			state.Offres.status = true;
		},
	},
});

export default offreSlice.reducer;
