import React, { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';
import { sendMailResetPassword } from '../../../redux/authSlice';

const validationSchema = yup.object({
	email: yup.string().email('Email invalide').required("L'email est obligatoire"),
});

const LoginHeader = () => {
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Bienvenue,</div>
			<div className='text-center h4 text-muted mb-5'>Merci de saisir votre email !</div>
		</>
	);
};

const ForgetPassword = () => {
	const { darkModeStatus } = useDarkMode();

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const formik = useFormik({
		initialValues: {
			email: '',
		},
		validationSchema,
		onSubmit: async (values) => {
			dispatch(sendMailResetPassword(values))
				.unwrap()
				.then((res) => {
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>Email envoyer avec succées </span>
						</span>,
						res.message,
						'success',
					);
				})
				.catch((error) => {
					console.log('error', error);
					if (error.message === 'Utilisateur non enregistré') {
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Error' size='lg' className='me-1' />
								<span>Verifier votre Email</span>
							</span>,
							'Utilisateur avec cette email n existe pas',
							'warning',
						);
					} else
						showNotification(
							<span className='d-flex align-items-center'>
								<Icon icon='Error' size='lg' className='me-1' />
								<span>Erreur</span>
							</span>,
							'On s excuse un erreur c est produit, veuillez réessayer plus tard',
							'danger',
						);
				});
		},
	});

	const handleGoBack = useCallback(() => navigate('/login'), [navigate]);

	return (
		<PageWrapper title='Réinitialiser le mot de passe' className={classNames('bg-info')}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='col-4'>
									<Button
										isOutline
										color={darkModeStatus ? 'light' : 'dark'}
										className={classNames('w-100 py-3', {
											'border-light': !darkModeStatus,
											'border-dark': darkModeStatus,
										})}
										icon='ArrowBack'
										size='sm'
										onClick={handleGoBack}>
										Retour
									</Button>
								</div>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<Logo width={200} />
									</Link>
								</div>

								<LoginHeader isNewUser={false} />

								<form className='row g-4'>
									<div className='col-12'>
										<FormGroup id='email' isFloating label='Votre email'>
											<Input
												type='email'
												autoComplete='email'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.email}
												isValid={formik.isValid}
												isTouched={formik.touched.email}
												invalidFeedback={formik.errors.email}
												validFeedback='Cela semble bon!'
											/>
										</FormGroup>
									</div>

									<div className='col-12'>
										<Button
											color='info'
											icon='Email'
											className='w-100 py-3'
											disable={!formik.isValid}
											onClick={formik.handleSubmit}>
											Envoyer email
										</Button>
									</div>
								</form>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default ForgetPassword;
